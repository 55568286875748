export const config = {
  api: {
    domain:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_DOMAIN
        : process.env.REACT_APP_DEV_API_DOMAIN || "https://localhost:1234",
  },
  cookies: {
    consentCookieLabel:
      process.env.REACT_APP_CONSENT_COOKIE_LABEL || "consent-cookie",
  },
  emails: {
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || "support@gptmyday.com",
  },
  labels: {
    brandName: process.env.REACT_APP_BRAND_NAME_LABEL || "",
  },
  links: {
    baseClientURL:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_WEB_STATIC_URL
        : "http://localhost:3001",
    appClientURL:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_WEB_APP_URL
        : "http://localhost:3000",
  },
};
