import React from "react";
import { v4 as uuidv4 } from "uuid";
import { config } from "../../config";
import { Mixpanel } from "../../utils/analytics";
// Types: (https://github.com/fkhadra/react-toastify#toast)
import { toast, ToastContent, ToastOptions, TypeOptions } from "react-toastify";
import "./index.css";

class Landing extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      ctaEmail: "",
      headerHeroPicPath: "/headerHeroPic.webp",
      footerHeroPicPath: "/footerHeroPic.webp",
    };

    this.renderHeader = this.renderHeader.bind(this);
    this.renderIntroPanel = this.renderIntroPanel.bind(this);
    this.renderFooterCTA = this.renderFooterCTA.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  componentDidMount(): void {
    Mixpanel.track("Visit", {});
  }

  displayToastMessage(
    type: TypeOptions,
    message: ToastContent,
    options: ToastOptions = {
      autoClose: 4000,
      position: toast.POSITION.BOTTOM_LEFT,
    }
  ) {
    if (process.env.NODE_ENV !== "production") {
      console.log("utils.toast.displayToastMessage()", type, message);
    }

    switch (type) {
      case "success":
        return toast.success(message, options);
      case "error":
        return toast.error(message, options);
      case "info":
        return toast.info(message, options);
      case "warning":
        return toast.warning(message, options);
    }
  }

  async handleSignUp(evt: Event | any) {
    try {
      evt.preventDefault();
      console.log("handleSignUp()");

      this.displayToastMessage("info", "Loading...");

      if (this.state.ctaEmail.length > 0) {
        (window as any).snaptr("track", "SIGN_UP");

        (window as any).rdt("track", "SignUp", {
          email: this.state.ctaEmail,
        });
        (window as any).ttq.track("CompleteRegistration", {
          description: "Sign Up",
          status: "submitted",
          content_type: "product",
          content_name: "GPTMyDay",
          content_id: "1",
          content_category: "tech",
          currency: "USD",
          value: 0.0,
          price: 0,
          quantity: 1,
          email: this.state.ctaEmail,
        });
        Mixpanel.track("Sign Up", {
          email: this.state.ctaEmail,
          tp: true,
        });
      }

      const resJSON = await fetch(
        `${config.api.domain}/api/v1/account/signup`,
        {
          method: "POST",
          mode: "cors",
          credentials: "omit",
          body: JSON.stringify({
            email: this.state.ctaEmail,
            tp: true,
            password: uuidv4().substring(0, 23), // temp password
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .catch((error) => {
          console.log(".then() error", error);
          throw new Error("Network Error");
        });
      if (resJSON.status >= 400) {
        throw new Error(resJSON.statusText);
      }
      if (resJSON.error) {
        throw new Error(resJSON.error);
      }

      this.displayToastMessage("success", "Success");

      this.displayToastMessage(
        "success",
        "Success :D Check your email to activate your account and change your temporary password."
      );

      window.location.replace(
        `${config.links.appClientURL}/auth?email=${this.state.ctaEmail}&view=activate`
      );
    } catch (error: Error | any) {
      console.log("handleSignUp() error:", error);
      this.displayToastMessage("error", error.message);
    }
  }

  renderHeader() {
    return (
      <header className="row">
        <div className="brand-name-container col-md-2 col-12">
          <h1>GPTMyDay</h1>
        </div>
        <div className="header-actions-container col-md-10 col-12">
          <a href="/legal?view=faq">FAQ</a>
        </div>
      </header>
    );
  }

  renderIntroPanel() {
    return (
      <main className="intro-panel-container row">
        <div className="content-info-container col-12">
          <h1>
            <strong className="bold-details-text">
              Save Time, Discover More
            </strong>
          </h1>
          <h2>GPTMyDay is the easiest way to use AI to plan your day</h2>
          <div className="cta-input-container">
            <input
              className="cta-input"
              placeholder="Email address"
              value={this.state.ctaEmail}
              onChange={(e) => this.setState({ ctaEmail: e.target.value })}
            />
            <button className="cta-button" onClick={this.handleSignUp}>
              Sign Up
            </button>
          </div>
          <div className="cta-footer">
            <span>
              Already have an account?
              <a
                className="login-link"
                href={`${config.links.appClientURL}/auth`}
              >
                Log In
              </a>
            </span>
            <br />
            <a
              href="https://www.toolpilot.ai/products/gptmyday-ai-to-plan-your-day"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.toolpilot.ai/cdn/shop/files/toolpilot-badge-w.png"
                alt="GPTMyDay Is Featured On ToolPilot.ai"
              ></img>
            </a>
          </div>
          <div className="intro-panel-illustration-container">
            <img
              alt="hero-content-info"
              src={process.env.PUBLIC_URL + this.state.headerHeroPicPath}
            ></img>
          </div>
        </div>
      </main>
    );
  }

  renderInfoPanels() {
    return (
      <div className="info-panels-container row">
        <div className="info-panel row">
          <div className="info-panel-desc col-md-6 col-12">
            <img
              src={process.env.PUBLIC_URL + "/planYourDay.webp"}
              className="card-img-top"
              alt="plan-preview"
            />
            <h1>PLAN YOUR DAY</h1>
            <p>
              Enter an activity type, location, budget, and more to get started!
            </p>
          </div>
          <div className="info-panel-desc col-md-6 col-12">
            <img
              src={process.env.PUBLIC_URL + "/waitForResults.webp"}
              className="card-img-top"
              alt="plan-preview"
            />
            <h1>WAIT FOR RESULTS</h1>
            <p>
              Click "Generate Plan" to receive a personalized daily plan
              generated by ChatGPT's AI technology.
            </p>
          </div>
        </div>
        <div className="info-panel row">
          <div className="info-panel-desc col-md-6 col-12">
            <img
              src={process.env.PUBLIC_URL + "/startTheDay.webp"}
              className="card-img-top"
              alt="plan-preview"
            />
            <h1>START THE DAY</h1>
            <p>
              Review and/or download the plan created for you and start your
              amazing day :D
            </p>
          </div>
          <div className="info-panel-desc col-md-6 col-12">
            <img
              src={process.env.PUBLIC_URL + "/revisitFavs.webp"}
              className="card-img-top"
              alt="plan-preview"
            />
            <h1>REVISIT FAVORITES</h1>
            <p>Review old plans in your own personalized history!</p>
          </div>
        </div>
      </div>
    );
  }

  renderTestimonials() {
    return (
      <div className="testimonals-section-container row">
        <h1>Testimonials</h1>
        <div className="testimonial-column col-md-4 col-12">
          <p>
            "I couldn't be happier with the results! Thanks to GPTMyDay, our
            night out was a HUGE success, and we can't wait to use it again :D"
            - Sophie
          </p>
        </div>
        <div className="testimonial-column col-md-4 col-12">
          <p>
            "Using AI to plan out my day has saved me so much time and effort.
            I'm able to see more of the city and experience new things, without
            the stress of trying to figure out a plan on my own." - Kylie
          </p>
        </div>
        <div className="testimonial-column col-md-4 col-12">
          <p>
            "GPTMyDay's personalized recommendations for activities,
            restaurants, and hotels were spot-on, and saved me so much time and
            hassle on my trip to Rome." - Matt
          </p>
        </div>
      </div>
    );
  }

  renderSubscriptionPlans() {
    return (
      <div className="pricing-section-container row">
        <h1>Pricing</h1>
        <div className="plans-row row">
          <div className="pricing-headlines col-xl-5 col-12">
            <h2>Pro (or free with limited features and ads)</h2>
            <h3>$4.99/month</h3>
          </div>
          <div className="pricing-details col-xl-7 col-12">
            <p>
              <strong className="bold-details-text">Ads:</strong>None
            </p>
            <p>
              <strong className="bold-details-text">Reports:</strong>Unlimited
            </p>
            <p>
              <strong className="bold-details-text">
                Downloads (PDF, iCAl, etc.):
              </strong>
              Unlimited
            </p>
            <p>
              <strong className="bold-details-text">
                Report storage (History):
              </strong>
              Unlimited
            </p>
            <p>
              <strong className="bold-details-text">Planner Features:</strong>
              All
            </p>
            <p>
              <strong className="bold-details-text">
                Access to new features:
              </strong>
              Forever
            </p>
            <p>
              <strong className="bold-details-text">Customer support:</strong>
              Within 24 hours (response time)
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderFooterCTA() {
    return (
      <div className="footer-cta-container row">
        <div className="content-info-container col-12">
          <h1>Don't miss out on the future</h1>
          <div className="cta-input-container">
            <input
              className="cta-input"
              placeholder="Email address"
              value={this.state.ctaEmail}
              onChange={(e) => this.setState({ ctaEmail: e.target.value })}
            />
            <button className="cta-button" onClick={this.handleSignUp}>
              Sign Up
            </button>
          </div>
          <div className="cta-footer">
            <span>
              Already have an account?
              <a
                className="login-link"
                href={`${config.links.appClientURL}/auth`}
              >
                Log In
              </a>
            </span>
          </div>
          <div className="footer-cta-illustration-container">
            <img
              alt="hero-content-info"
              src={process.env.PUBLIC_URL + this.state.footerHeroPicPath}
            ></img>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="landing-container">
        {this.renderHeader()}
        {this.renderIntroPanel()}
        {this.renderInfoPanels()}
        {this.renderTestimonials()}
        {this.renderSubscriptionPlans()}
        {this.renderFooterCTA()}
      </div>
    );
  }
}

export default Landing;
