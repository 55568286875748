import React from "react";
import queryString from "query-string";
import { config } from "../../config";
import "./index.css";

class Legal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const parsedQuery = queryString.parse(window.location.search);
    if (process.env.NODE_ENV !== "production") {
      console.log("LegalView parsedQuery:", parsedQuery);
    }

    this.state = {
      legalList: ["terms", "privacy", "cookies", "FAQ"],
      currentView: parsedQuery.view ? parsedQuery.view : "terms",
      brandName: config.labels.brandName,
      supportEmail: config.emails.supportEmail,
      supportEmailLink: `mailto:${config.emails.supportEmail}?subject=Support%20Request&body=Please be very clear about your issue so our team can swiftly solve it!`,
    };

    this.handleUpdateState = this.handleUpdateState.bind(this);
    this.renderLegalList = this.renderLegalList.bind(this);
    this.getLegalContent = this.getLegalContent.bind(this);
  }

  capitalize(label: string) {
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  handleUpdateState(key: string, value: any) {
    this.setState({
      [key]: value,
    });
  }

  renderHeader() {
    return (
      <div className="LandingHeaderContainer">
        <h1>{this.state.brandName}</h1>
      </div>
    );
  }

  renderTerms() {
    return (
      <div className="LegalViewContent">
        <p>Terms and Conditions</p>
        <br />
        TERMS AND CONDITIONS OF USE FOR GPTMyDay Welcome to GPTMyDay, the
        AI-powered travel planner tool owned and operated by{" "}
        {this.state.brandName}. These terms and conditions ("Terms") govern your
        use of the GPTMyDay website at https://www.gptmyday.com, the GPTMyDay
        web application at https://app.gptmyday.com, and the GPTMyDay API at
        https://api.gptmyday.com (collectively, the "Site"). By accessing or
        using the Site, you agree to be bound by these Terms. USE OF GPTMYDAY
        1.1 Eligibility. You must be at least 18 years of age to use the Site.
        By accessing or using the Site, you represent and warrant that you are
        at least 18 years of age. 1.2 License. Subject to your compliance with
        these Terms, {this.state.brandName} grants you a limited, non-exclusive,
        non-transferable, revocable license to use the Site solely for your
        personal, non-commercial use. 1.3 Prohibited Conduct. You agree that you
        will not use the Site for any unlawful purpose or in any manner that
        could damage, disable, overburden, or impair the Site. You also agree
        not to: (a) use the Site to solicit or promote any illegal activity; (b)
        use the Site to harm or exploit minors; (c) impersonate any person or
        entity or falsely state or otherwise misrepresent your affiliation with
        a person or entity; (d) use any robot, spider, scraper, or other
        automated means to access the Site or any content on the Site for any
        purpose without our express written permission; (e) interfere with or
        disrupt the Site or servers or networks connected to the Site, or
        disobey any requirements, procedures, policies, or regulations of
        networks connected to the Site; (f) use the Site to send or store any
        infringing, obscene, threatening, libelous, or otherwise unlawful or
        tortious material, including but not limited to materials harmful to
        children or violative of third-party privacy rights; (g) attempt to gain
        unauthorized access to any portion or feature of the Site, or any other
        systems or networks connected to the Site or to any{" "}
        {this.state.brandName}
        server, or to any of the services offered on or through the Site, by
        hacking, password "mining," or any other illegitimate means; (h) use any
        device, software, or routine to interfere with the proper functioning of
        the Site or any transaction conducted on the Site, or with any other
        person's use of the Site; (i) use the Site in a manner that violates any
        applicable law or regulation or these Terms; or (j) encourage or enable
        any other individual to do any of the foregoing. 1.4 Modifications to
        the Site. {this.state.brandName} reserves the right to modify, suspend,
        or discontinue the Site or any portion thereof at any time, without
        notice to you. You agree that {this.state.brandName} will not be liable
        to you or to any third party for any modification, suspension, or
        discontinuance of the Site or any portion thereof. ACCOUNTS 2.1
        Registration. To use certain features of the Site, you may be required
        to register for an account. You agree to provide accurate, current, and
        complete information during the registration process and to update such
        information as necessary to keep it accurate, current, and complete.
        {this.state.brandName} reserves the right to suspend or terminate your
        account if any information provided during the registration process or
        thereafter proves to be inaccurate, not current, or incomplete. 2.2
        Account Security. You are responsible for maintaining the
        confidentiality of your account login information and password and for
        restricting access to your computer or mobile device. You agree to
        accept responsibility for all activities. 2.3 Account Termination. You
        may terminate your account at any time by contacting{" "}
        {this.state.brandName}.{this.state.brandName} may terminate your account
        at any time without notice, for any reason or no reason, including if{" "}
        {this.state.brandName} believes that you have violated these Terms or
        any applicable law, rule, or regulation, or if you have engaged in any
        conduct that {this.state.brandName}
        deems to be inappropriate, harmful, or offensive. 2.4 Account
        Information. You acknowledge and agree that {this.state.brandName} may
        access, preserve, and disclose your account information and any content
        submitted by you if required to do so by law or in a good faith belief
        that such access, preservation, or disclosure is reasonably necessary
        to: (a) comply with legal process; (b) enforce these Terms; (c) respond
        to claims that any content violates the rights of third parties; (d)
        respond to your requests for customer service; or (e) protect the
        rights, property, or personal safety of {this.state.brandName}, its
        users, or the public. CONTENT 3.1 Ownership. You acknowledge and agree
        that
        {this.state.brandName} owns all legal right, title, and interest in and
        to the Site, including any intellectual property rights that subsist in
        the Site (whether those rights happen to be registered or not, and
        wherever in the world those rights may exist). You agree that you will
        not modify, rent, lease, loan, sell, distribute, or create derivative
        works based on the Site, in whole or in part. 3.2 User Content. You are
        solely responsible for any content that you submit to the Site,
        including but not limited to travel plans, reviews, ratings, comments,
        and messages (collectively, "User Content"). You acknowledge and agree
        that {this.state.brandName} does not endorse any User Content and is not
        responsible or liable for any User Content. You represent and warrant
        that you own or have the necessary licenses, rights, consents, and
        permissions to use and authorize {this.state.brandName} to use all
        patent, trademark, trade secret, copyright, or other proprietary rights
        in and to any User Content in the manner contemplated by these Terms.
        3.3 License. By submitting any User Content to the Site, you grant{" "}
        {this.state.brandName} a non-exclusive, transferable, sublicensable,
        royalty-free, worldwide license to use, copy, modify, create derivative
        works based on, distribute, publicly display, publicly perform, and
        otherwise exploit in any manner such User Content in all formats and
        distribution channels now known or hereafter devised (including in
        connection with the Site and {this.state.brandName}'s business and on
        third-party sites and services), without further notice to or consent
        from you, and without the requirement of payment to you or any other
        person or entity. 3.4 Removal. {this.state.brandName} reserves the
        right, but is not obligated, to review any User Content and to remove
        any User Content at any time and for any reason, including if{" "}
        {this.state.brandName} determines in its sole discretion that the User
        Content violates these Terms or is otherwise inappropriate. PRIVACY 4.1
        Privacy Policy. Your use of the Site is subject to{" "}
        {this.state.brandName}'s Privacy Policy available at [insert hyperlink
        to Privacy Policy] which is incorporated by reference into these Terms.
        DISCLAIMER OF WARRANTIES 5.1 No Warranties. The Site is provided on an
        "as is" and "as available" basis, without warranty of any kind, either
        express or implied, including without limitation any warranty for
        information, services, uninterrupted access, or products provided
        through or in connection with the Site, including without limitation the
        software licensed to you and the results obtained through the Site. 5.2
        Use at Your Own Risk. You acknowledge and agree that your use of the
        Site and any content or services provided through the Site is at your
        own risk. You acknowledge and agree that {this.state.brandName} is not
        responsible or liable for any harm, loss, or damage (including but not
        limited to personal injury, property damage, loss of data, or loss of
        profits) arising from or related to your use of the Site or any content
        or services provided through the Site. LIMITATION OF LIABILITY 6.1
        Limitation of Liability. To the fullest extent permitted by law,{" "}
        {this.state.brandName} shall not be liable for any indirect, incidental,
        special, consequential, or exemplary damages, including but not limited
        to damages for loss of profits, goodwill, use, data, or other intangible
        losses (even if {this.state.brandName} has been advised of the
        possibility of such damages), arising out of or in connection with your
        use of the Site or any content or services provided through the Site.
        6.2 Maximum Liability. To the fullest extent permitted by law,{" "}
        {this.state.brandName}'s total liability to you in connection with the
        Site or these Terms, whether in contract, tort (including negligence),
        or otherwise, shall not exceed the amount paid by you, if any, to
        {this.state.brandName} during the twelve (12) months immediately
        preceding the event giving rise to the liability. INDEMNIFICATION 7.1
        Indemnification. You agree to indemnify and hold harmless{" "}
        {this.state.brandName}, its affiliates, officers, directors, employees,
        agents, and licensors from and against any claims, liabilities, damages,
        losses, and expenses, including without limitation reasonable attorneys'
        fees and costs, arising out of or in any way connected with (a) your
        access to or use of the Site or any content or services provided through
        the Site, (b) your User Content, or (c) your violation of these Terms or
        any applicable law or regulation. GENERAL 8.1 Governing Law. These Terms
        shall be governed by and construed in accordance with the laws of the
        [state/country] without giving effect to any principles of conflicts of
        law. 8.2 Dispute Resolution. Any dispute arising out of or in connection
        with these Terms or the Site shall be resolved through binding
        arbitration in accordance with the [state/country] Arbitration Act. The
        arbitration shall be conducted in [city, state/country] before a single
        arbitrator mutually agreed upon by the parties, or if the parties cannot
        agree, appointed by the [state/country] Arbitration Association. The
        arbitration shall be conducted in English and the decision of the
        arbitrator shall be final and binding on the parties. 8.3 Entire
        Agreement. These Terms constitute the entire agreement between you and{" "}
        {this.state.brandName} regarding the use of the Site and supersede all
        prior agreements and understandings, whether written or oral, relating
        to the subject matter of these Terms. 8.4 Waiver and Severability. The
        failure of {this.state.brandName} to exercise or enforce any right or
        provision of these Terms shall not constitute a waiver of such right or
        provision. If any provision of these Terms is held to be invalid or
        unenforceable by a court or arbitrator, the remaining provisions of
        these Terms shall remain in full force and effect. 8.5 Assignment. You
        may not assign these Terms or any of your rights or obligations
        hereunder, whether by operation of law or otherwise, without{" "}
        {this.state.brandName}'s prior written consent. {this.state.brandName}{" "}
        may assign these Terms or any of its rights or obligations hereunder,
        without your prior written consent. By using the Site, you acknowledge
        that you have read, understood, and agree to be bound by these Terms.
      </div>
    );
  }

  renderPrivacy() {
    return (
      <div className="LegalContent">
        <p>Privacy Policy</p>
        <br />
        PRIVACY POLICY FOR GPTMYDAY Effective Date: April 16th, 2023 At
        GPTMyDay, we are committed to protecting your privacy. This privacy
        policy explains how we collect, use, and protect your personal
        information. Please read this privacy policy carefully to understand our
        practices regarding your personal information. By using our website (the
        “Site”) or any services provided through the Site, you acknowledge that
        you have read, understood, and agree to be bound by this privacy policy.
        1. INFORMATION WE COLLECT 1.1 Personal Information. We collect personal
        information that you voluntarily provide to us when you use the Site or
        any services provided through the Site, such as your name, email
        address, phone number, and travel preferences. 1.2 Usage Information. We
        collect usage information about your use of the Site and any services
        provided through the Site, such as the pages you visit, the features you
        use, and the actions you take. 1.3 Device Information. We collect
        information about the device you use to access the Site, such as your IP
        address, browser type, and operating system. 2. USE OF INFORMATION 2.1
        Personal Information. We use your personal information to provide the
        services you request through the Site, such as creating a travel
        itinerary. We may also use your personal information to communicate with
        you about the Site or our services, to provide customer support, or to
        send you promotional materials that we think may be of interest to you.
        2.2 Usage Information. We use usage information to improve the Site and
        our services, to analyze trends, and to administer the Site. 2.3 Device
        Information. We use device information to ensure that the Site is
        functioning properly and to troubleshoot any problems that may arise. 3.
        SHARING INFORMATION 3.1 Third-Party Service Providers. We may share your
        personal information with third-party service providers that perform
        services on our behalf, such as hosting the Site, providing customer
        support, or analyzing usage data. 3.2 Business Transfers. We may share
        your personal information in connection with a merger, acquisition, or
        sale of all or a portion of our assets. 3.3 Legal Requirements. We may
        disclose your personal information if required to do so by law or in the
        good-faith belief that such action is necessary to comply with
        applicable laws or respond to a court order, judicial or other
        government subpoena, or warrant. 4. COOKIES AND TRACKING TECHNOLOGIES
        4.1 Cookies. We use cookies, which are small text files that are stored
        on your device, to help us analyze how users use the Site and to
        personalize your experience. 4.2 Google Analytics. We use Google
        Analytics to collect usage data about the Site. Google Analytics uses
        cookies to collect information about how often users visit the Site,
        what pages they visit when they do so, and what other sites they used
        prior to coming to the Site. We use the information we get from Google
        Analytics only to improve the Site and our services. 4.3 Mixpanel. We
        use Mixpanel to analyze usage data about the Site. Mixpanel uses cookies
        to collect information about how users use the Site. We use the
        information we get from Mixpanel only to improve the Site and our
        services. 4.4 Pixels (Reddit, Tiktok, Snap). We use Pixels (Reddit,
        Tiktok, Snap) to analyze usage data about the Site. Pixels (Reddit,
        Tiktok, Snap) uses cookies to collect information about how users use
        the Site. We use the information we get from Pixels (Reddit, Tiktok,
        Snap) only to improve the Site and our services. 5. YOUR RIGHTS 5.1
        Access. You have the right to access the personal information we hold
        about you. 5.2 Correction. You have the right to request correction of
        any inaccurate or incomplete personal information we hold about you. 5.3
        Erasure. You have the right to request erasure of your personal
        information in certain circumstances, such as when the information is no
        longer necessary in relation to the purposes for which it was collected
        or when you withdraw your consent. We will comply with your request
        unless we have a legitimate reason for not doing so, such as a legal
        obligation to retain the information. 5.4 Objection. You have the right
        to object to the processing of your personal information on grounds
        relating to your particular situation, but only to the extent that the
        legal basis for the processing is that the processing is necessary for
        the purposes of the legitimate interests pursued by us or by a third
        party. If you make such an objection, we will cease to process the
        personal information unless we can demonstrate compelling legitimate
        grounds for the processing which override your interests, rights and
        freedoms, or for the establishment, exercise or defense of legal claims.
        5.5 Withdrawal of Consent. If we rely on your consent to process your
        personal information, you have the right to withdraw your consent at any
        time. This will not affect the lawfulness of any processing carried out
        before you withdraw your consent. 5.6 Complaints. If you believe that we
        have infringed your rights under applicable data protection laws, you
        have the right to lodge a complaint with a supervisory authority. Data
        Retention We will retain your personal information for as long as
        necessary to fulfill the purposes for which it was collected, unless a
        longer retention period is required by law or permitted by law for
        legitimate business purposes. When we no longer need your personal
        information, we will securely delete or destroy it. 6. SECURITY We use
        reasonable organizational, technical, and administrative measures to
        protect your personal information from unauthorized access, use,
        disclosure, alteration, or destruction. However, no data transmission
        over the Internet or electronic storage system can be guaranteed to be
        100% secure. Children's Privacy The Site and our services are not
        directed to children under the age of 16, and we do not knowingly
        collect personal information from children under the age of 16. Changes
        to this Privacy Policy We reserve the right to change this privacy
        policy at any time. If we make material changes to this privacy policy,
        we will notify you by email or by posting a notice on the Site prior to
        the effective date of the changes. Your continued use of the Site or any
        services provided through the Site after the effective date of the
        changes constitutes your acceptance of the new privacy policy. We
        encourage you to periodically review this privacy policy for the latest
        information on our privacy practices. If you have any questions about
        this privacy policy, please contact us{" "}
        <a href={this.state.supportEmailLink}>here</a>
      </div>
    );
  }

  renderCookies() {
    return (
      <div className="LegalContent">
        <p>Cookie Policy</p>
        <br />
        1. INTRODUCTION This Cookie Policy explains how GPTMyDay ("we", "us",
        "our") uses cookies and similar technologies to recognize you when you
        visit our website, app, and API ("Site") at https://www.gptmyday.com,
        https://app.gptmyday.com, and https://api.gptmyday.com. It explains what
        these technologies are and why we use them, as well as your rights to
        control our use of them. 2. WHAT ARE COOKIES? Cookies are small text
        files that are stored on your browser or device by websites, apps,
        online media, and advertisements. They are designed to store information
        about your interactions with these entities and remember your
        preferences for future visits. 3. WHY DO WE USE COOKIES? We use cookies
        and similar technologies to improve your browsing experience on our
        Site, understand how you interact with our content and services, and
        personalize your experience. 4. TYPES OF COOKIES we use 4.1. Essential
        Cookies: These cookies are necessary for the Site to function properly
        and cannot be switched off in our systems. They are usually only set in
        response to actions made by you, such as setting your privacy
        preferences, logging in, or filling in forms. 4.2. Analytics Cookies: We
        use Google Analytics, Mixpanel, and Pixels (Reddit, Tiktok, Snap) to
        analyze how visitors use our Site. These cookies collect information
        about how visitors use our Site, including the number of visitors, the
        pages visited, and the sources of traffic. This information is used to
        improve our Site and understand the interests and behaviors of our
        visitors. 4.3. Advertising Cookies: We use advertising cookies to show
        personalized ads to our visitors based on their interests and behaviors.
        These cookies collect information about your browsing history, such as
        the pages you have visited and the links you have clicked on. 5. YOUR
        RIGHTS 5.1. Cookie Preferences: You have the right to choose which types
        of cookies you accept or reject by clicking on the Cookie Settings link
        on our Site. 5.2. Withdrawal of Consent: If you have given your consent
        to the use of cookies, you have the right to withdraw your consent at
        any time by adjusting your cookie settings. 5.3. Disabling Cookies: You
        can disable cookies by adjusting your browser settings. Please note that
        disabling cookies may limit your access to certain features of our Site.
        6. CHANGES TO THIS COOKIE POLICY We reserve the right to change this
        Cookie Policy at any time. If we make material changes to this Cookie
        Policy, we will notify you by email or by posting a notice on the Site
        prior to the effective date of the changes. Your continued use of the
        Site after the effective date of the changes constitutes your acceptance
        of the new Cookie Policy. We encourage you to periodically review this
        Cookie Policy for the latest information on our cookie practices. If you
        have any questions about this Cookie Policy, please contact us{" "}
        <a href={this.state.supportEmailLink}>here</a>
      </div>
    );
  }

  renderFAQ() {
    return (
      <div className="FAQContentContainer">
        <div className="FAQContent">
          <div className="FAQQuestion">What is {this.state.brandName}?</div>
          <div className="FAQAnswer">
            {this.state.brandName} is the easiest way to use AI to plan your
            day! <a href="/">Learn more here!</a>
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">Is it free?</div>
          <div className="FAQAnswer">
            Yes! But, {this.state.brandName} operates on both a
            subscription-based business model with recurring, monthly
            subscription plans to create a better experience for you!{" "}
            <a href="/">Learn more here!</a>
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">
            How old do I have to be to use {this.state.brandName}?
          </div>
          <div className="FAQAnswer">
            You must be at least 18 years old to use {this.state.brandName}.
          </div>
        </div>
      </div>
    );
  }

  renderLegalList() {
    return (
      <div className="LegalListContainer">
        <ul className="LegalList">
          {this.state.legalList.map((view: string, key: number) => {
            const isActive = this.state.currentView === view.toLowerCase();
            const linkStyle = isActive
              ? "LegalListItem-Active"
              : "LegalListItem-Inactive";
            view = isActive ? view.toUpperCase() : this.capitalize(view);
            return (
              <li key={key} className="LegalListItem">
                <div
                  className={`LegalListItemLink ${linkStyle}`}
                  onClick={(_: Event | any) => {
                    this.setState({ currentView: view.toLowerCase() });
                  }}
                >
                  {view}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  getLegalContent() {
    switch (this.state.currentView) {
      case "terms":
        return this.renderTerms();
      case "privacy":
        return this.renderPrivacy();
      case "cookies":
        return this.renderCookies();
      case "faq":
        return this.renderFAQ();
      default:
        return this.renderTerms();
    }
  }

  renderLegalView() {
    return (
      <div className="LegalViewContainer">
        <div className="LegalViewHeader">
          <p>{this.state.currentView.toUpperCase()}</p>
        </div>
        <div className="LegalViewContentContainer">
          {this.getLegalContent()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="LegalContainer">
        {this.renderHeader()}
        {this.renderLegalList()}
        {this.renderLegalView()}
      </div>
    );
  }
}

export default Legal;
